interface Breadcrumb {
  title: string;
  path: string;
}

let breadcrumbs: Breadcrumb[] = [];

export function useBreadcrumbs(data: Breadcrumb[] | null = null) {
  if (data) {
    breadcrumbs = data;
  }
  return breadcrumbs;
}
