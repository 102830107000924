<template>
  <div
    v-if="!$route.meta.disableBreadcrumbs"
    class="py-2 md:py-5 flex gap-2 items-center font-bold font-anomaliaLight text-sm">
    <ArrowRight class="h-4 cursor-pointer" @click="$router.back()" />

    <RouterLink :to="{ name: 'home' }">
      <span class="hover:underline text-primary">{{ t('lobby') }} /</span>
    </RouterLink>

    <template v-for="(item, id) in breadcrumbs" :key="id">
      <RouterLink :to="item.path">
        <span class="hover:underline text-primary">{{ item.title }} /</span>
      </RouterLink>
    </template>

    <span v-if="title" class="hover:underline">
      {{ title }}
    </span>
  </div>
</template>
<script setup lang="js">
import { ArrowRight } from 'lucide-vue-next';
import { useBreadcrumbs } from '@amit/composables';
import { useI18n } from 'vue-i18n';
const { t } = useI18n();

defineProps({
  title: {
    type: String,
    default: '',
  },
});

const breadcrumbs = useBreadcrumbs();
</script>
