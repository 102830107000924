<template>
  <div
    class="h-screen w-screen flex flex-col px-5 bg-cover"
    :style="{
      backgroundImage: `url('/mentoring/background.svg')`,
    }">
    <div class="flex gap-5 pt-3 h-14">
      <NavBreadcrumbs id="breadcrumb" :title="`קבוצת ${currentGroup?.name}`" />
      <div class="flex gap-5 items-center">
        <BaseDropdown text="Bottom" class="element-dropdown">
          <template #trigger>
            <div class="h-10 relative">
              <img src="/mentoring/element-input.svg" alt="" />
              <div
                class="w-full h-full absolute px-4 top-0 flex items-center justify-between">
                <span class="text-white text-sm grow">
                  {{ student?.fullName }}
                </span>
                <ChevronDown class="stroke-white" />
              </div>
            </div>
          </template>
          <ListGroup class="max-h-[500px]">
            <BaseScrollbar>
              <ListGroupItem
                v-for="studentItem in students"
                :key="studentItem.id"
                @click="goToStudent(studentItem.id)">
                {{ studentItem.fullName }}
              </ListGroupItem>
            </BaseScrollbar>
          </ListGroup>
        </BaseDropdown>
        <div class="flex items-center gap-2">
          <p class="text-white text-sm w-24">
            הצגה לפי: {{ typeInput === tabsTypes.AREAS ? 'זירות' : 'צרכים' }}
          </p>
          <SelectInput
            id="type-switch"
            v-model="typeInput"
            :disabled="!areasList.length"
            type="switch"
            label=""
            @input-selected="typeInput = !typeInput"/>
        </div>

        <BaseDropdown text="Bottom" class="element-dropdown">
          <template #trigger>
            <div class="h-10 relative">
              <img src="/mentoring/element-input.svg" alt="" />
              <div
                class="w-full h-full absolute px-4 top-0 flex items-center justify-between">
                <span class="text-white text-sm grow">
                  {{
                    activeElement
                      ? activeElement.title
                      : `בחרו ${typeInput == tabsTypes.NEEDS ? 'צורך' : 'זירה'}`
                  }}
                </span>
                <ChevronDown class="stroke-white" />
              </div>
            </div>
          </template>
          <ListGroup class="list-wrapper">
            <ListGroupItem
              v-for="item in selectedList"
              :key="item.id"
              @click="activeSurvey = item.id">
              {{ item.title }}
            </ListGroupItem>
          </ListGroup>
        </BaseDropdown>
      </div>
    </div>
    <Transition
      enter-active-class="transition ease-in-out duration-700 opacity"
      enter-from-class="opacity-0"
      enter-to-class="opacity-100"
      leave-active-class="transition ease-in-out duration-100 opacity"
      leave-from-class="opacity-100"
      leave-to-class="opacity-0">
      <div :key="`${student?.id}`">
        <div
          v-if="activeSurvey"
          class="overflow-hidden h-full flex mt-10 gap-10">
          <div class="flex justify-center items-start pt-10 w-2/12">
            <img
              class="w-[200px]"
              :src="`/mentoring/${activeElement.theme}_${
                typeInput == tabsTypes.AREAS ? 'planet' : 'rocket'
              }.svg`"
              alt=""/>
          </div>
          <div
            class="w-10/12 px-5 overflow-hidden"
            :style="{ height: 'calc(100vh - 100px' }">
            <BaseScrollbar
              :suppress-scroll-x="false"
              :suppress-scroll-y="false">
              <div
                v-if="questions.length"
                class="h-full flex flex-wrap gap-10 w-screen mb-48">
                <div
                  v-for="ques in questions"
                  :key="ques.id"
                  class="mt-5 h-fit">
                  <div class="relative flex items-center justify-center z-10">
                    <BaseBadge
                      pill
                      size="sm"
                      :type="getBadgeTheme(typeInput === tabsTypes.AREAS, ques)"
                      class="rounded-full py-1 px-2 text-white"
                      :style="{
                        background: getBackgrouhnd(
                          getBadgeTheme(typeInput === tabsTypes.AREAS, ques),
                        ),
                      }">
                      <span class="text-white">
                        {{ getBadgeText(typeInput === tabsTypes.AREAS, ques) }}
                      </span>
                    </BaseBadge>
                  </div>
                  <div class="-mt-4 w-80 relative h-48">
                    <img
                      class="w-80 h-48 z-20"
                      src="/mentoring/element-background.svg"
                      alt=""/>
                    <div
                      class="h-60/100 absolute text-white top-4 m-5 overflow-y-auto overflow-x-hidden">
                      <BaseScrollbar>
                        <h2 class="text-white mb-2 font-simplerBold">
                          {{
                            ques.title.content.blocks[0].data.text.replace(
                              /(<([^>]+)>)/gi,
                              '',
                            )
                          }}
                        </h2>
                        <p class="mt-5 text-white font-simplerRegular">
                          {{ getSubmission(ques) }}
                        </p>
                      </BaseScrollbar>
                    </div>
                  </div>
                </div>
              </div>
              <div
                v-else
                class="relative flex items-center w-fit h-full text-white">
                <img
                  class="w-80 h-48 z-20"
                  src="/mentoring/element-background.svg"
                  alt=""/>
                <p
                  class="absolute top-0 z-20 text-white text-lg flex items-center justify-center h-full w-full top-0">
                  שאלון זה עוד לא נענה
                </p>
              </div>
            </BaseScrollbar>
          </div>
        </div>
        <div
          v-else-if="selectedList.length"
          class="flex gap-20 xl:gap-32 items-center justify-center grow mt-10">
          <div
            v-for="(item, index) in selectedList"
            :key="item.id"
            class="cursor-pointer"
            :class="{
              'pt-48': [1, 3, 5].includes(index),
            }"
            @click="activeSurvey = item.id">
            <img
              class="w-[200px]"
              :src="`/mentoring/${item.theme}_${
                typeInput == tabsTypes.AREAS ? 'planet' : 'rocket'
              }.svg`"
              alt=""/>
          </div>
        </div>
        <div v-else>
          <div
            v-if="selectedList.length"
            class="w-fit relative h-fit mt-10 flex items-center ms-20">
            <img
              class="w-80 h-48 z-20"
              src="/mentoring/element-background.svg"
              alt=""/>
            <p
              class="z-20 text-lg w-full text-white absolute top-0 h-full flex items-center justify-center">
              שאלון זה עוד לא נענה
            </p>
          </div>
          <div v-else>
            <LoadingOverlay
              :active="true"
              :can-cancel="false"
              :is-full-page="false"
              :opacity="0.2"/>
          </div>
        </div>
      </div>
    </Transition>
  </div>
</template>
<script setup lang="ts">
import {
  SelectInput,
  BaseDropdown,
  ListGroup,
  ListGroupItem,
  BaseBadge,
  BaseScrollbar,
} from '@nwire/amit-design-system';
import { ChevronDown } from 'lucide-vue-next';
import { computed, onMounted, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { IStudent } from '/@/app/models/interfaces';
import NavBreadcrumbs from '/@/views/layouts/NavBreadcrumbs.vue';
import { useSurvey } from '/@/app/composables/useSurvey';
import { useSurveyStore } from '/@/app/store/surveys';
import { useAccountStore } from '/@/app/store/account';
import LoadingOverlay from 'vue-loading-overlay';

const tabsTypes = {
  AREAS: true,
  NEEDS: false,
};

const route = useRoute();
const router = useRouter();
const accountStore = useAccountStore();
const surveyStore = useSurveyStore();

const typeInput = ref(tabsTypes.NEEDS);

const {
  studentId,
  needsList,
  areasList,
  getAreaQuestion,
  getNeedQuestion,
  getSubmission,
  getBadgeTheme,
  getBadgeText,
} = useSurvey();

const selectedList = computed(() =>
  typeInput.value === tabsTypes.AREAS ? areasList.value : needsList.value,
);

const activeSurvey = ref();

const activeElement = computed(() =>
  selectedList.value?.find(element => element.id === activeSurvey.value),
);

const questionsByArea = computed(() => getAreaQuestion(activeSurvey.value));
const questionsByNeed = computed(() => getNeedQuestion(activeSurvey.value));
const questions = computed(() =>
  typeInput.value === tabsTypes.AREAS
    ? questionsByArea.value
    : questionsByNeed.value,
);

const student = ref<IStudent>();
const currentGroup = computed(() =>
  accountStore.groups.find(group => group.id === route.params.group),
);
const students = computed<IStudent[]>(() => currentGroup.value?.students ?? []);
const selectedStudent = ref(-1);

const routeStudent = computed(() => route.query.student);
watch([routeStudent, students], () => {
  getSelectedStudent();
});
const getSelectedStudent = () => {
  if (!students.value.length) return;
  selectedStudent.value = students.value.findIndex(
    student => student.id === route.query.student,
  );
  selectedStudent.value =
    selectedStudent.value > -1 ? selectedStudent.value : 0;
  student.value = students.value[selectedStudent.value];
  studentId.value = student.value.id;
};

onMounted(() => {
  surveyStore.init({
    survey: route.params.survey as string,
    group: route.params.group,
  });
  getSelectedStudent();
});

const goToStudent = id => {
  router.push({
    name: 'survey-progress-visual',
    params: {
      group: route.params.group,
      survey: route.params.survey,
    },
    query: {
      student: id,
    },
  });
};

const getBackgrouhnd = color => {
  switch (color) {
    case 'blue':
      return '#0E70CC';
    case 'green':
      return '#28849E';
    case 'purple':
      return '#DE72EB';
    case 'pink':
      return '#FF257B';
    case 'default':
      return '#E6B28F';
    case 'yellow':
      return '#F3AA43';
    case 'sky-blue':
      return '#42CDF5';
    case 'red':
      return '#F55D51';
    case 'brown':
      return '#FF772C';
    default:
      return '#aaaaaa';
  }
};
</script>

<style scroped>
#type-switch {
  @apply bg-transparent w-auto p-0 !border-none items-center justify-center;
}

#type-switch .toggle-bg {
  @apply border-none rounded-none h-10 w-20 bg-no-repeat bg-transparent;
  background-image: url('/mentoring/toggle.svg');
}

#type-switch .toggle-bg:after {
  @apply border-none rounded-none h-8 w-8 mt-0.5 bg-no-repeat bg-transparent;
  background-image: url('/mentoring/toggle-button.svg');
}
input:checked + .toggle-bg:after {
  transform: translateX(100%);
}

#type-switch span {
  @apply hidden;
}
#breadcrumb > span {
  @apply text-white;
}
#breadcrumb svg {
  @apply stroke-white;
}
.element-dropdown > .absolute {
  @apply rounded-full;
}

.element-dropdown > .absolute > ul {
  @apply rounded-3xl bg-secondary-200;
}
</style>
